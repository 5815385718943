@import-normalize;

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
main {
  flex-shrink: 0;
  flex-basis: auto;
}

header,
footer {
  flex-grow: 0;
}

main {
  flex-grow: 1;
}

strong {
  font-weight: 800;
}

.Card {
  width: 900px;
  padding: 50px 70px 50px 70px;
  margin: 0 auto;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  letter-spacing: 2px;
  background-size: cover;
  position: relative;
  border-top: 25px solid #36c781;
  border-radius: 20px;
}

.Card::after {
  position: absolute;
  left: 50%;
  right: 0;
  top: -25px;
  border-top: 25px solid #ff911c;
  border-top-right-radius: 20px;
  content: "";
}

.Card__Badge {
  position: absolute;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 2;
  margin-left: 55%;
  margin-top: -75px;
  width: 180px;
}

.Card__Badge p {
  text-transform: uppercase;
  color: white;
  padding-top: 1rem;

  letter-spacing: 1px;
  line-height: 1.8rem;
  text-align: center;
  height: 100%;
  font-size: 1.8em;
}

.Card__Badge p span {
  display: block;
  height: 100%;
}

.Card__QrCode {
  background-color: white;
  padding-left: 2em;
  padding-top: 25px;
  padding-bottom: 15px;
}

.Card__QrCode--BLUE {
  border-bottom: solid 3px #6cbfe6;
}

.Card__QrCode--BLACK {
  border-bottom: solid 3px #858789;
}

.Card__Badge--BLUE {
  background-color: #0570ad;
  border: solid 3px #6cbfe6;
  border-top: none;
}

.Card__Badge--BLACK {
  background-color: #000;
  border: solid 3px #858789;
  border-top: none;
}

.Card--BLACK {
  background-color: #000;
  background-image: url("./card_backgrounds/black-bg-arrows.png");
}

.Card--BLUE {
  background-color: #0570ad;
  background-image: url("./card_backgrounds/blue-bg-arrows.png");
}

.Card--WHITE {
  background-color: white;
  background-image: url("./card_backgrounds/white-arrows.png");
  color: black;
  box-shadow: 0 0 20px #ccc;
}

.Card__Header {
  padding-right: 180px;
}

.Card__Header img {
  width: 330px;
  margin-bottom: 1.5rem;
}

.Card__Body {
  display: grid;
  grid-template-columns: 200px 2fr;
  padding: 30px 0 0;
}

.Card__Image img {
  border-radius: 10px;
  width: 100%;
}

.Card__Info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 20px 40px;
}

.Card__Info p {
  margin: 0;
  padding: 0;
}

.Card__Info h3 {
  margin: 0;
  padding: 0;
  font-size: 1.2em;
}

.Card__Valid {
  display: flex;
  gap: 2em;
}

.Card__InfoName {
  padding-top: 25px;
  margin-bottom: 20px;
}

.Card__InfoName p,
.Card__Valid p {
  font-size: 2em;
  letter-spacing: 0.5px;
  min-height: 60px;
  line-height: 1.2em;
}

.Card__TRG__Logo {
  position: absolute;
  width: 170px;
  right: 30px;
  bottom: 30px;
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (max-width: 900px) {
  .Header {
    margin-top: 30px;
  }

  .CardWrapper {
    transform: rotate(90deg) scale(0.6);
  }

  .Card {
    margin-bottom: 320px;
    margin-top: 180px;
    transform: scale(1.7);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 540px) {
  .Card {
    margin-bottom: 290px;
    margin-top: -70px;
    margin-right: 0;
    transform: scale(1.1);
  }
}

@media (max-width: 360px) {
  .Card {
    margin-top: -120px;
    margin-bottom: 240px;
    transform: scale(1.1);
  }
}
