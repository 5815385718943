.App {
  display: flex;
  align-items: center;
}

.App__Content {
  width: 100%;
  height: 410px;
  margin: 0;
  background-size: cover;
}

.membership-photo {
  max-width: 250px;
  width: 100%;
}
