:root {
  --brand: #54be8c;
}

* {
  font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.text-primary {
  color: var(--brand) !important;
}

.btn-primary {
  background-color: var(--brand) !important;
  border-color: var(--brand) !important;
}

a {
  color: var(--brand);
}

.default-layout {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.default-layout--white {
  background-color: white;
}

main,
.main {
  align-items: center;
  justify-content: center;
  display: flex;
}

header {
  padding: 30px;
}

header img {
  width: 190px;
}

footer {
  padding: 30px 0;
  background-color: white;
  border-top: 1px solid var(--bs-light-rgb);
}

footer img {
  width: 190px;
}

.default-layout h1,
.default-layout h2,
.default-layout h3,
.default-layout h4,
.default-layout h5,
.default-layout h6 {
  letter-spacing: -2px;
}
