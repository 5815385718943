.profile__avatar {
  border-radius: 50%;
  border: 10px solid var(--brand);
  margin: 20px auto;
  width: 80%;
}

.profile__avatar img {
  border-radius: 50%;
  border: 10px solid white;
}
